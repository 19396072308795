/* eslint-disable */
import React from "react";

export default {
  en: {
    quote: "A real alternative to Google Maps",
    author: "Damien Anfroy, Marketing Director",
    sector: " Online hairdresser appointment scheduling",
    interviewees: ["Damien Anfroy, Marketing Director"],
    service: "Dynamic & Static Maps, Geocoding",
    geo: "France",
    blocks: [
      {
        title: "Kiute",
        description: (
          <span>
            The mission of{" "}
            <a
              href="https://www.kiute.fr/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Kiute
            </a>{" "}
            is to provide a platform allowing anyone to search for nearby
            hairdressing salons. The solution is also focused on filling the
            blanks in the agenda, by giving out unbeatable prices. As Marketing
            Director, Damien works on the acquisition and retention of customers
            within both the website and the application of Kiute.
          </span>
        ),
        img: "clientLogo",
      },
      {
        title: "Kiute & Jawg",
        description: (
          <span>
            In their model, proximity is essential because their only target are
            local businesses. Users of Kiute are looking for a hairdresser near
            their work, or their home. This is where the map comes in handy,
            allowing them to be guided in their choice of salon. For Damien,{" "}
            <q>
              the ability to locate our partner hairdressing salons is strategic
              !
            </q>
            .
          </span>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <span>
            Since July 2018,{" "}
            <a
              href="https://medium.com/@LoicOrtola/mapocalypse-migrer-depuis-google-maps-maintenant-1-2-ed12ed3b5c21"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Google Maps has increased their pricing.
            </a>{" "}
            At the same time, this arousal coincided with an increase in volume
            at Kiute. Damien had to quickly find an alternative to handle these
            fixed costs. It is a demo of Jawg's services that convinced him.{" "}
            <q>The quality is there, the price is there, there is no debate!</q>
          </span>
        ),
      },
      {
        title: "",
        description: (
          <q>
            Very quickly, we were reassured about Jawg's ability to guide us
          </q>
        ),
      },
      {
        title: "",
        description: (
          <span>
            For Damien, Jawg's adoption was <q>easy</q> and the{" "}
            <q>
              change of the mapping system did not affect the conversion rate at
              all
            </q>
            . The Jawg team was able to assist Kiute's technical team during the
            integration and advise them on the adjustments to be made, in order
            to facilitate their migration. Since the integration, Damien never
            had to worry about operations. For him,
            <q>it's a real comfort in everyday life</q>.
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            After more than a year of use, Damien estimates that he found in
            Jawg <q>a real alternative to Google Maps</q> for his maps and
            geocoding services.
          </span>
        ),
      },
      {
        title: "Jawg Maps in 3 words?",
        description: "Efficient, Reliable and Relevant!",
      },
    ],
  },
  fr: {
    quote: "Une réelle alternative par rapport à Google Maps",
    author: "Damien Anfroy, Directeur Marketing",
    sector: "Plateforme de réservation de coiffeur en ligne",
    interviewees: ["Damien Anfroy, Directeur Marketing"],
    service: "Dynamic & Static Maps, Geocoding",
    geo: "France",
    blocks: [
      {
        title: "Kiute",
        description: (
          <span>
            La mission de{" "}
            <a
              href="https://www.kiute.fr/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Kiute
            </a>{" "}
            est d’accroître l’activité des salons de coiffure en comblant les
            créneaux vides. En proposant une solution simple et clé en main à
            leurs salons partenaires, ils permettent aussi aux clients de
            bénéficier de prestations à des prix défiant toute concurrence. En
            tant que Directeur Marketing, Damien travaille l'acquisition et la
            fidélisation des clients au sein du site et de l’application de
            Kiute.
          </span>
        ),
        img: "clientLogo",
      },
      {
        title: "Kiute & Jawg",
        description: (
          <span>
            Quand Damien explique ce qui est essentiel dans le modèle de Kiute,
            il évoque forcément la proximité. Kiute, c'est d'abord une
            plateforme qui met en lien des commerces de proximité avec des
            utilisateurs qui ont leurs habitudes. Près de leur travail ou sur le
            chemin vers leur domicile, la carte permet de les accompagner dans
            leur choix de salon. Pour Damien,{" "}
            <q>
              &#160;la capacité à localiser nos salons de coiffure partenaires
              est stratégique !&#160;
            </q>
            .
          </span>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <span>
            En juillet 2018, Google Maps{" "}
            <a
              href="https://medium.com/@LoicOrtola/mapocalypse-migrer-depuis-google-maps-maintenant-1-2-ed12ed3b5c21"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              augmente significativement ses tarifs
            </a>{" "}
            et cela coïncide avec une augmentation du volume chez Kiute. Damien
            doit vite chercher une alternative pour maîtriser ces coûts fixes.
            C’est une démo des services de Jawg qui l’a convaincu.{" "}
            <q>
              &#160;La qualité est là, le prix est là, il n’y a pas beaucoup de
              débat !&#160;
            </q>
          </span>
        ),
      },
      {
        title: "",
        description: (
          <q>
            Très vite, on a été rassuré sur la capacité de Jawg de nous
            accompagner sur cette partie là.
          </q>
        ),
      },
      {
        title: "",
        description: (
          <span>
            Pour Damien, l’adoption de Jawg s’est faite <q>facilement</q> et le{" "}
            <q>
              &#160;changement de système de cartographie n’a pas du tout pesé
              sur le taux de conversion&#160;
            </q>
            . La Jawg team a pu accompagner l’équipe technique de Kiute lors de
            l’intégration et les conseiller sur les réglages à effectuer. Depuis
            la mise en place, Damien n’a pas eu à se soucier du fonctionnement.
            Pour lui, <q>&#160;c’est un vrai confort au quotidien&#160;</q>.
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            Après plus d’un an d’utilisation, Damien estime avoir trouvé en Jawg{" "}
            <q>&#160;une réelle alternative par rapport à Google Maps&#160;</q>{" "}
            pour ses services de carte et de geocoding.
          </span>
        ),
      },
      {
        title: "Jawg Maps en 3 mots ?",
        description: "Efficace, Fiable et Pertinent !",
      },
    ],
  },
};
